/*jshint unused:false*/
import axios from 'axios';
import Vue from 'vue';
import blazy from './components/blazy';

if (!Object.entries)
  Object.entries = function( obj ){
    var ownProps = Object.keys( obj ),
        i = ownProps.length,
        resArray = new Array(i); // preallocate the Array
    while (i--)
      resArray[i] = [ownProps[i], obj[ownProps[i]]];

    return resArray;
};

const Search = new Vue({
  el: '#load-more-results',
  data: function () {
    return {
      Loading: true,
      Api: '',
      UrlQuery: '',
      UrlParams: {},
      Post: {
        "Page": 1
      },
      Results: [],
      Articles: [],
      PageActive: false,
      Total: 0,
      IsLastPage: true,
      Page: 1,
      Increment: 15,
      NumOfPages: 0,
      LoadingMore: false,
    }
  },
  beforeMount() {
    // get all of the query string parameters
    // https://stackoverflow.com/questions/8648892/convert-url-parameters-to-a-javascript-object#answer-15383092
    const params = location.search.substring(1);
    // console.log(params);

    // this.UrlParams = JSON.parse(`{"${params.replace(/&/g, '","').replace(/=/g,'":"')}"}`, (key, value) => { 
    //   return key === "" ? value : decodeURIComponent(value) 
    // })     ${window.location.pathname}


    // Object.keys(this.UrlParams).forEach((param) => {
    //   this[param] = typeof this[param] == 'object' ? this.UrlParams[param].split(',') : this.UrlParams[param];
    // })
    const dataElement = document.getElementById('load-more-results').dataset;

    const dataAttributes = Object.entries(dataElement).filter((keyValue) => {
      if (keyValue[1] !== 'null' && keyValue[1] !== '' && keyValue[0] !== 'page' && keyValue[0] !== 'culture') {
        return keyValue;
      }
    });

    const queryArray = dataAttributes.map((keyValue) => {
      let key = keyValue[0];
      const value = keyValue[1];
      if (key === 'username') {
        key = 'u'
      } else if (key === 'featured') {
        key = 'f'
      } else if (key === 'featuredbanner') {
        key = 'h'
      } else if (key === 'category') {
        key = 'c'
      } else if (key === 'branch'){
        key = 'b'
      }
      return `${key}=${value}`;
    })
    
    // const cultureCode = window.location.pathname.split('/').filter((culture) => {
    //   if(culture === 'fr-ca' || culture === 'en-ca') {
    //     return culture;
    //   }
    // });

    this.Api = `/${dataElement.culture}/api/article/${dataElement.page}?${queryArray.join('&', ',')}`;
  },
  mounted() {
    // this.Api = this.$el.dataset;
    this.getResults();
  },
  methods: {
    updateUrlQuery(paramName, paramValue) {
      // Find the specific key in the query string that needs to be updated
      // literally match the string "test_ref=", followed by one or more things 
      // that are not the query string parameter separator ("&"), and replace 
      // with the captured match ("test_ref="), followed by the value of the 
      // variable updated_test_ref
      // https://stackoverflow.com/questions/5413899/search-and-replace-specific-query-string-parameter-value-in-javascript#answer-5413913
      const paramToUpdate = new RegExp(`(${paramName}=)[^&#]*`);


      if (this.UrlQuery.match(paramToUpdate)) {
        // If it exists, just replace it
        this.UrlQuery = this.UrlQuery.replace(paramToUpdate, `$1${paramValue}`);
      } else {
        // check if we need to add an ampersand or a question mark to string
        const separator = this.UrlQuery.indexOf('?') !== -1 ? "&" : "?";
        this.UrlQuery = this.UrlQuery + separator + paramName + "=" + paramValue;
      }
    },

    setPageNum() {
      this.Post.Page = this.Page || 1;
      this.updateUrlQuery('Page', this.Page);

      if (history.pushState) {
        const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${this.UrlQuery}`;
        window.history.pushState({path:newUrl},'',newUrl);
      }
    },
    loadMore() {
      this.Page = this.Page + 1;

      this.LoadingMore = true;
      this.setPageNum();
      this.getResults(null, true);
      // this.showResults(this.Results, true);
    },
    getResults(e, loadMore) {
      if (!loadMore) {
        this.Loading = true;
      }
      this.UrlQuery = window.location.search;

      this.setPageNum();
      axios.get(`${this.Api}&page=${this.Page}`, this.Post)
      .then((res) => {
        // this.Results = res.data.articles;
        this.Total = res.data.totalElements;
        this.NumOfPages = res.data.totalPages;
        this.Page = res.data.currentPage;

        setTimeout(() => {

          // this.IsLastPage = res.data.IsLastPage;
          if (e) {
            document.querySelector('.c-list-articles').scrollIntoView({
              behavior: 'smooth', 
              block: 'nearest', 
              inline: 'nearest'
            });
          }
          if (loadMore) {
            const lastItemIndex = this.Results.length - 1;
            this.Results = this.Results.concat(res.data.articles);
            Vue.nextTick()
            .then(() => {
              this.LoadingMore = false;
              document.querySelector(`[data-list-index="${lastItemIndex + 1}"] a`).focus();
            });
          } else {
            if (res.data.articles) {
              this.Results = res.data.articles;
            } else {
              this.Results = [];
            }
            this.Loading = false;
          }
          blazy.checkImages();
        }, 500)
      })
      .catch(e => {
        console.log(e);

        setTimeout(() => {
          this.Loading = false;
        }, 500)
      })
    },
  }
});
